import qs from "querystring";

import { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

export const useQueryParams = (defaultParam = {}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const data = {};
  for (const [key, value] of searchParams.entries()) {
    data[key] = value;
  }

  const history = useHistory();

  const { pathname } = useLocation();

  if (data.page === undefined) {
    defaultParam = {
      page: 1,
      size: 10,
    };
  }

  const [params, setParams] = useState({
    ...data,
    ...defaultParam,
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    for (const [key] of url.searchParams.entries()) {
      url.searchParams.delete(key);
    }

    const search = qs.stringify(params);
    history.push([pathname, search].join("?"));
  }, [params, history, pathname]);

  const updateParams = (newData) => {
    const [changed, updatedParams] = setChanges(newData, params);
    if (changed) {
      setParams(updatedParams);
    }
  };

  const updatePaginate = ({ page, size }) => {
    updateParams({
      ...params,
      page: page || params.page,
      size: size || params.size,
    });
  };

  const updateFilter = (filter) => {
    const [changed, updatedParams] = setChanges(filter, params);
    if (changed) {
      // reset page to 1 if filter changed
      updatedParams.page = 1;

      setParams(updatedParams);
    }
  };

  const clearParams = () => {
    setParams({});
  };

  /**
   * Compare to get changes from new data and set changes to old data.
   * Property changed to null or undefined or empty string will be removed.
   * @param {object} newData New data
   * @param {object} oldData Old data
   * @returns [changed, updatedOldData] A flag to know if there is change or not and updated old data with changes from new data
   */
  const setChanges = (newData, oldData) => {
    let changed = false;
    if (oldData && newData) {
      const updatedOldData = { ...oldData };
      for (const key in newData) {
        if (newData[key] !== updatedOldData[key]) {
          changed = true;
          if (newData[key]) {
            updatedOldData[key] = newData[key];
          } else if (updatedOldData.hasOwnProperty(key)) {
            // If new value is falsy (null, undefined, empty string) and old data has this property
            // then remove this property
            delete updatedOldData[key];
          }
        }
      }
      return [changed, updatedOldData];
    }
    return [changed, oldData];
  };

  return {
    params: Object.keys(params).reduce((prev, acc) => {
      prev[acc] = Number(params[acc]) || params[acc];
      return prev;
    }, {}),
    updateParams,
    updatePaginate,
    updateFilter,
    clearParams,
  };
};

export default useQueryParams;
