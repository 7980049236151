import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import API from "../api";
import { convertBase64 } from "../helper";
import { toast } from "react-toastify";
// import "./style.scss";

/*
 * Use inline styling instead of react-quill classes for align
 */
const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
/*
 * Quill/React-Quill only offers image uploads from local machine. This
 * lets user input image URL
 */
function imageHandler() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async () => {
    const file = input.files ? input.files[0] : null;
    if (file) {
      toast.warning("Đang tải hình ảnh ");
      const image_base64 = await convertBase64(file);
      await API.postParam(`/upload/upload-news-image.php`, {
        image_base64: image_base64,
      }).then((res) => {
        if (res.success) {
          const range = this.quill.getSelection();
          this.quill.insertEmbed(
            range.index,
            "image",
            `/upload/news_images/${res.file_name}`
          );
        } else {
          toast.error(res.message);
        }
      });
    }
  };
}

export default class RichTextEditor extends Component {
  componentDidMount() {
    // We're changing hyperlink tooltip because default one is https://quilljs.com/
    const { hyperlinkTooltip } = this.props;

    const input = document.querySelector("input[data-link]");
    input.dataset.link = hyperlinkTooltip;
    input.placeholder = hyperlinkTooltip;
  }

  render() {
    const { value, onChange, className, ...otherProps } = this.props;

    const classes = classNames("rich-text-editor", className);

    return (
      <ReactQuill
        className={classes}
        theme="snow"
        value={value}
        modules={RichTextEditor.modules}
        formats={RichTextEditor.formats}
        onChange={onChange}
        {...otherProps}
      />
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
  toolbar: {
    container: [
      // [{ header: ["", 1, 2, 3] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    handlers: {
      image: imageHandler,
    },
  },
  clipboard: {
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See http://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
];

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  hyperlinkTooltip: PropTypes.string,
};
