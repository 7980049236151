import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import API from "../api";
import { caculateOffSet, caculatePage, totalKpiFormat } from "../helper";
import Paginator from "./paginato";

export default function ListKpiUser(props) {
  const { visible, onClose, id, name } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    page: 1,
    size: 15,
    totalPages: 10,
  });
  const size = 10;

  useEffect(() => {
    if (id) {
      getTarget(id, 0);
    }
  }, [id]);

  const onPageChange = (newPage) => {
    getTarget(id, newPage);
  };
  const getTarget = async (id, page) => {
    await API.getAPIData(
      `/api/get-target-by-user-id.php?id=${id}&size=${size}&offset=${caculateOffSet(
        page,
        size
      )}`
    ).then((res) => {
      if (res.success) {
        setData(res.data);
        setPage({
          page: page,
          size: size,
          totalPages: caculatePage(res.total, size),
        });
      } else {
        setData([]);
        setPage({
          page: 0,
          size: 0,
          totalPages: 0,
        });
      }
    });
  };

  return (
    <Modal size="xl" isOpen={visible}>
      <ModalHeader>Danh sách KPI - {name}</ModalHeader>
      <ModalBody>
        <div className="col-12">
          <div className="card">
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        className="text-uppercase text-xxs font-weight-bolder"
                      >
                        Nhân viên
                      </th>
                      <th
                        rowSpan={2}
                        className="text-center text-uppercase text-xxs font-weight-bolder"
                      >
                        Quý
                      </th>
                      <th
                        rowSpan={2}
                        className="text-center text-uppercase text-xxs font-weight-bolder"
                      >
                        Năm
                      </th>
                      <th
                        colSpan={5}
                        className="text-center text-uppercase text-xxs font-weight-bolder"
                      >
                        WHAT_HOW
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center text-uppercase text-xxs font-weight-bolder">
                        Tin cậy
                      </th>
                      <th className="text-center text-uppercase text-xxs font-weight-bolder">
                        Sáng tạo
                      </th>
                      <th className="text-center text-uppercase text-xxs font-weight-bolder">
                        Đam mê
                      </th>
                      <th className="text-center text-uppercase text-xxs font-weight-bolder">
                        Điểm tổng kết
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex px-2 py-1">
                              <div>
                                <img
                                  src={"/api/images/" + value.url_avata}
                                  className="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user1"
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">
                                  {value.full_name}
                                </h6>
                                <p className="text-xs text-secondary mb-0">
                                  {value.email}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle text-center text-sm">
                            {value.target}
                          </td>
                          <td className="align-middle text-center text-sm">
                            {value.year}
                          </td>
                          <td className="align-middle text-center text-sm">
                            {value.what_trust}-{value.how_trust}
                          </td>
                          <td className="align-middle text-center text-sm">
                            {value.what_creation}-{value.how_creation}
                          </td>
                          <td className="align-middle text-center text-sm">
                            {value.what_passion}-{value.how_passion}
                          </td>
                          <td className="align-middle text-sm">
                            <span className="badge badge-sm bg-gradient-success">
                              {totalKpiFormat(value.what_total_score)}
                            </span>{" "}
                            <span className="badge badge-sm bg-gradient-success">
                              {totalKpiFormat(value.how_total_score)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="page-right">
                <Paginator
                  {...page}
                  onPageChange={(newpage) => onPageChange(newpage)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose("");
            }}
          >
            Đóng
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
