import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import * as Icon from "react-feather";
import Select from "react-select";
import {
  add30Minutes,
  isEmpty,
  setHoursAndMinuteForDay,
  firstTimeisGreaterThanSecondTime,
  convertMDYHHMM,
  addZeroNumber,
} from "../helper";
import API from "../api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import "dayjs/locale/fr";
import { toast } from "react-toastify";
import RichTextEditor from "../Components/RichTextEditor";

export default function PopupView(props) {
  const { visible, onClose, setIsLoading, id = 0 } = props;
  const [isError, setIsError] = useState(false);
  const [listEmail, setListEmails] = useState([]);
  const [quill, setQuill] = useState("");
  const now = new Date();
  const defTimeFrom =
    now.getMinutes() > 30
      ? (now.getHours() + 1).toString().padStart(2, "0") + ":00"
      : now
          .getHours()
          .toString()
          .padStart(2, "0") + ":30";
  const defTimeTo = add30Minutes(defTimeFrom);
  const [title, setTitle] = useState("");
  const [selectEmails, setSelectEmail] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    setHoursAndMinuteForDay(now, defTimeFrom)
  );
  const [timeFrom, setTimeFrom] = useState(defTimeFrom);
  const [dateTo, setDateTo] = useState(setHoursAndMinuteForDay(now, defTimeTo));
  const [timeTo, setTimeTo] = useState(defTimeTo);
  const [meetings, setMeetings] = useState([]);
  const [selectMeeting, setSelectMeeting] = useState(0);
  const [host, setHost] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    if (!!visible) {
      getEmails();
      getLocations();
      if (id) {
        setIsLoading(true);
        API.getAPIData(`/api/meeting/detail_calendar.php?id=${id}`).then(
          (res) => {
            if (res.success) {
              const det_calendar = res.data;
              const start_date = new Date(det_calendar.start_date);
              const end_date = new Date(det_calendar.end_date);

              setDateFrom(start_date);
              setTimeFrom(
                `${addZeroNumber(start_date.getHours())}:${addZeroNumber(
                  start_date.getMinutes()
                )}`
              );
              setDateTo(end_date);
              setTimeTo(
                `${addZeroNumber(end_date.getHours())}:${addZeroNumber(
                  end_date.getMinutes()
                )}`
              );
              setQuill(det_calendar.description);
              setSelectMeeting(det_calendar.meeting_id);
              setHost(det_calendar.host);
              setTitle(det_calendar.title);
              const mapEmail = det_calendar.emails.split(",");
              const convertToTypeSelect = mapEmail.map((x) => {
                return { value: x, label: x };
              });
              setSelectEmail(convertToTypeSelect);
              setIsEdit(false);
              setIsCancel(res.can_cancel || false);
            } else {
              toast.error("Lỗi lấy thông tin chi tiết calendar!");
              setIsCancel(false);
            }
          }
        );
        setIsLoading(false);
      } else {
        clearData();
        setIsEdit(true);
        setIsCancel(false);
      }
    }
  }, [visible]);

  const clearData = () => {
    setTitle("");
    setSelectMeeting(0);
    setDateTo(setHoursAndMinuteForDay(now, defTimeTo));
    setTimeTo(defTimeTo);
    setSelectEmail([]);
    setDateFrom(setHoursAndMinuteForDay(now, defTimeFrom));
    setTimeFrom(defTimeFrom);
    setQuill("");
  };

  const handleChange = (html) => {
    setQuill(html);
  };

  const getEmails = async () => {
    await API.getAPIData("/api/users/list_email_users.php").then((response) => {
      const emails = response.data.map((value) => {
        return { value: value.email, label: value.email };
      });

      setListEmails(emails || []);
    });
  };

  const getLocations = async () => {
    await API.getAPIData("/api/meeting/list_meeting.php").then((response) => {
      setMeetings(response.data || []);
    });
  };

  var timeSlots = [];

  for (var hour = 0; hour <= 23; hour++) {
    for (var minute = 0; minute < 60; minute += 30) {
      var time =
        hour.toString().padStart(2, "0") +
        ":" +
        minute.toString().padStart(2, "0");
      timeSlots.push(time);
    }
  }

  const onChangeTimeFrom = (timeF) => {
    setTimeFrom(timeF);
    setDateFrom(setHoursAndMinuteForDay(dateFrom, timeF));
    if (!firstTimeisGreaterThanSecondTime(timeTo, timeF)) {
      const newTime = add30Minutes(timeF);
      setTimeTo(newTime);
      const [hour, minute] = newTime.split(":");
      dateTo.setHours(hour);
      dateTo.setMinutes(minute);
    }
  };

  const onChangeTimeTo = (timeT) => {
    setTimeTo(timeT);
    setDateTo(setHoursAndMinuteForDay(dateTo, timeT));
  };

  const onChangeDateFrom = (value) => {
    if (value && value.$d) {
      let date = new Date(value.$d);
      const [hour, minute] = timeFrom.split(":");
      date.setHours(hour);
      date.setMinutes(minute);

      if (
        new Date(date) - new Date(dateTo) > 0 ||
        dateTo === "0000-00-00 00:00:00"
      ) {
        setDateTo(date);
      }
      setDateFrom(date);
    }
  };

  const onChangeDateTo = (value) => {
    if (value && value.$d) {
      let date = new Date(value.$d);
      const [hour, minute] = timeTo.split(":");

      date.setHours(hour);
      date.setMinutes(minute);
      setDateTo(date);
    }
  };

  const onSaveData = async () => {
    setIsError(false);
    if (dateTo - dateFrom < 1) {
      toast.error(
        "Thời gian bất đầu không được nhỏ hơn thời gian kết thúc vui lòng kiểm tra lại!"
      );
      return;
    }
    let dataSend = {
      start_date: convertMDYHHMM(dateFrom),
      end_date: convertMDYHHMM(dateTo),
      title: title,

      description: quill,
      meeting_id: selectMeeting,
    };

    const errorValue = Object.values(dataSend).filter(
      (x) => x === "" || x === undefined || x < 1
    );

    if (
      errorValue.length > 0 ||
      dateFrom === "Invalid Date" ||
      dateTo === "Invalid Date"
    ) {
      setIsError(true);
      toast.error("Giá trị không được để trống!");
      return;
    }
    setIsLoading(true);

    dataSend["id"] = id;
    dataSend["emails"] = selectEmails.map((x) => x.value);

    await API.postParam("/api/meeting/save-calendar.php", dataSend).then(
      (res) => {
        if (res.success) {
          toast.success(res.message);
          onClose();
        } else {
          toast.error(res.message);
        }
      }
    );

    setIsLoading(false);
  };

  const onCancel = async () => {
    await API.getAPIData(`/api/meeting/cancel-calendar.php?id=${id}`).then(
      (res) => {
        if (res.success) {
          toast.success(res.message);
          onClose();
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <h5>{id ? `Người tổ chức: ${host}` : "Đặt phòng họp"}</h5>
          <button
            className="model-close"
            onClick={() => {
              onClose();
            }}
          >
            x
          </button>
        </div>
        <div className="d-flex my-2">
          <div className="label-calendar">
            <Icon.Edit2 size={18} />
          </div>
          <div
            className={
              isError && isEmpty(title)
                ? "input-group input-group-outline border-input-error"
                : "input-group input-group-outline"
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder="Thêm chủ đề"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={100}
              disabled={!isEdit}
            />
          </div>
        </div>
        <div className="d-flex my-2">
          <div className="label-calendar">
            <Icon.Users size={18} />
          </div>
          <div className="input-group input-group-outline">
            <Select
              options={listEmail}
              className="width-100"
              placeholder="Thêm email"
              isMulti={true}
              value={selectEmails}
              onChange={(e) => setSelectEmail(e)}
              isOptionDisabled={(option) => (option.disabled = !isEdit)}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="label-calendar">
            <Icon.Clock size={18} />
          </div>
          <div className="row width-100">
            <div className="col-md-3 my-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={
                    isError && isEmpty(dateFrom) ? "border-input-error" : ""
                  }
                  value={dateFrom}
                  onChange={(newValue) => onChangeDateFrom(newValue)}
                  minDate={now}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!isEdit}
                />
              </LocalizationProvider>
            </div>
            <div className="col-md-2 my-2">
              <div
                className={
                  isError && isEmpty(timeFrom)
                    ? "input-group input-group-outline border-input-error"
                    : "input-group input-group-outline"
                }
              >
                <select
                  className="form-control"
                  value={timeFrom}
                  onChange={(e) => {
                    onChangeTimeFrom(e.target.value);
                  }}
                  disabled={!isEdit}
                >
                  {timeSlots.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div
              className="col-md-2"
              style={{ alignSelf: "center", textAlign: "center" }}
            >
              <hr />
            </div>
            <div className="col-md-3 my-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={
                    isError && isEmpty(dateTo) ? "border-input-error" : ""
                  }
                  value={dateTo}
                  onChange={(newValue) => onChangeDateTo(newValue)}
                  minDate={dateFrom}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  disabled={!isEdit}
                />
              </LocalizationProvider>
            </div>
            <div className="col-md-2 my-2">
              <div
                className={
                  isError && isEmpty(timeTo)
                    ? "input-group input-group-outline border-input-error"
                    : "input-group input-group-outline"
                }
              >
                <select
                  className="form-control"
                  value={timeTo}
                  onChange={(e) => {
                    onChangeTimeTo(e.target.value);
                  }}
                  disabled={!isEdit}
                >
                  {timeSlots.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex my-2">
          <div className="label-calendar">
            <Icon.MapPin size={18} />
          </div>
          <div
            className={
              isError && selectMeeting === 0
                ? "input-group input-group-outline border-input-error"
                : "input-group input-group-outline"
            }
          >
            <select
              className="form-control"
              value={selectMeeting}
              onChange={(e) => {
                setSelectMeeting(e.target.value);
              }}
              disabled={!isEdit}
            >
              <option value={0}>---Chọn phòng họp---</option>
              {meetings.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}-{item.location}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="d-flex my-2">
          <div className="label-calendar">
            <Icon.AlignLeft size={18} />
          </div>
          <div
            className={
              isError && isEmpty(quill)
                ? "border-input-error width-100"
                : "width-100"
            }
          >
            <RichTextEditor
              placeholder="Nội dung..."
              value={quill}
              onChange={handleChange}
              readOnly={!isEdit}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            className="btn btn-small btn-light"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              onClose();
            }}
          >
            Đóng
          </Button>
          {!id && (
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                onSaveData();
              }}
              disabled={!isEdit}
            >
              Đăng ký phòng
            </Button>
          )}

          {!!id && isCancel && (
            <Button
              className="bg-gradient-prim ary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                onCancel();
              }}
            >
              Huỷ phòng họp
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
