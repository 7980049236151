import React, { useState } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import NavBar from "../Components/navbar";
import Loading from "../Components/loading";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/vi";
import API from "../api";
import { convertYMD } from "../helper";
import PopupView from "./popupView";

function Calendar() {
  document.title = "Calendar";
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [detail, setDetail] = useState({});
  const [id, setId] = useState(0);

  const getCalendarData = async (fetchInfo, successCallback) => {
    try {
      const date = new Date();

      let startDate = new Date(date.getFullYear(), date.getMonth() + 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      if (fetchInfo) {
        startDate = fetchInfo.start;
        endDate = fetchInfo.end;
      }
      const response = await API.postParam("/api/meeting/list_calendar.php", {
        start_date: convertYMD(startDate),
        end_date: convertYMD(endDate),
      });

      successCallback(
        response.data.map((event) => {
          return {
            id: event.id,
            title: event.title,
            start: event.start_date,
            end: event.end_date,
            start_date: event.start_date,
            end_date: event.end_date,
            metting_id: event.metting_id,
            description: event.description,
            host: event.host,
            location: event.location,
            name: event.name,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onClosePopup = () => {
    setId(0);
    setShowPopup(false);
  };

  function renderEventContent(eventInfo) {
    const extendedProps = eventInfo.event._def.extendedProps;
    const inFo = eventInfo.event;
    const detail_id = inFo.id;
    const startDateTime = extendedProps.start_date.split(" ");
    const endDateTime = extendedProps.end_date.split(" ");
    const timeStart = startDateTime[1] ? startDateTime[1].slice(0, 5) : "00:00";
    const timeEnd = endDateTime[1] ? endDateTime[1].slice(0, 5) : "00:00";

    return (
      <>
        <div
          className="dot-in-line cursor-pointer"
          onClick={() => {
            setDetail(inFo.extendedProps);
            setShowPopup(true);
            setId(detail_id);
          }}
        >
          <b>{timeStart}</b>-<b>{timeEnd}</b>: <i>{extendedProps.name}</i>
        </div>
      </>
    );
  }

  return (
    <section>
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Danh sách nhân viên" />
        <div className="container-fluid py-2">
          {isLoading && <Loading />}
          <div className="row calendar-ui">
            <FullCalendar
              locale={esLocale}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "new",
                center: "title",
              }}
              customButtons={{
                new: {
                  text: "Đặt phòng họp",
                  click: () => setShowPopup(true),
                },
              }}
              events={(fetchInfo, successCallback, failureCallback) =>
                getCalendarData(fetchInfo, successCallback, failureCallback)
              }
              nowIndicator={true}
              eventContent={renderEventContent}
            />
          </div>
        </div>
        <Footer />
      </main>

      <PopupView
        visible={showPopup}
        onClose={onClosePopup}
        data={detail}
        setIsLoading={setIsLoading}
        id={id}
      />
    </section>
  );
}

export default Calendar;
