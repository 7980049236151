import React, { useState, useEffect } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import NavBar from "../Components/navbar";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Confirm from "../Components/confirm";
import { isEmpty, phoneValidation, emailValidation } from "../helper";
import { toast } from "react-toastify";
import API from "../api";
import * as Constant from "../constant";
import { useParams } from "react-router-dom";
import Loading from "../Components/loading";
import { useQuery } from "react-query";
import "dayjs/locale/fr";
import Documents from "../Components/documents";
import Select from "react-select";

function UserCreate() {
  document.title = "Users";
  const dateNow = new Date();
  const { id } = useParams();
  const [user, setUser] = useState({
    id: 0,
    full_name: "",
    address: "",
    email: "",
    birth_date: "",
    phone: "",
    position_id: 10,
    working_date: "",
    introduce: "",
    status: "activity",
    url_avata: "",
    sex: "male",
    insurance_code: "",
    id_card: "",
    team: "smart",
    tax_code: "",
    isdeleted: 0,
    paid_holidays: 0,
    total_holidays: 0,
    rank: "officer",
    departments: "",
  });
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isError, setIsError] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileNameDel, setFileNameDel] = useState("");
  const [isdelete, setIsdelete] = useState(false);
  const [typeDoc, setTypeDoc] = useState("pl");
  const [uploadAtDoc, setUploadAtDoc] = useState(dateNow);
  const [docMonth, setDocMonth] = useState(dateNow.getMonth() + 1);

  let history = useHistory();
  const onToListView = () => {
    history.push(`/nhan-vien${window.location.search}`);
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getUserDetail();
      setDocuments([]);
    }
    getDepartment();
    getPositions();
    setIsLoading(false);
  }, []);
  const { refetch } = useQuery(
    ["GET_DOC_USER", id, typeDoc, uploadAtDoc, docMonth],
    () =>
      API.postParam("/api/documents/user-documents.php", {
        id: id || 0,
        type: typeDoc,
        year: uploadAtDoc.getFullYear(),
        month: docMonth,
      }),
    {
      retry: 3,
      refetchOnWindowFocus: true,
      onSuccess: (res) => {
        if (res.success) {
          setDocuments([...res.data]);
        } else {
          setDocuments([]);
        }
      },
    }
  );

  const getUserDetail = async () => {
    const response = await API.createOrUpdateUser(
      "/api/users/get-user.php?id=" + id
    );

    if (response.success) {
      const data = response.data;

      setUser({
        id: data.id,
        full_name: data.full_name,
        address: data.address,
        email: data.email,
        birth_date: data.birth_date,
        phone: data.phone,
        position_id: data.position_id,
        working_date: data.working_date,
        introduce: data.description,
        status: data.status,
        url_avata: data.url_avata,
        sex: data.sex,
        insurance_code: data.insurance_code,
        id_card: data.id_card,
        team: data.team,
        tax_code: data.tax_code,
        isdeleted: data.isdeleted,
        paid_holidays: data.paid_holidays,
        total_holidays: data.total_holidays,
        rank: data.rank,
        departments: data.departments,
      });
    } else {
      toast.error(response.message);
    }
  };

  const validateParam = () => {
    let validateUser = { ...user };
    delete validateUser.introduce;
    delete validateUser.id;
    delete validateUser.url_avata;
    delete validateUser.isdeleted;
    delete validateUser.paid_holidays;
    delete validateUser.total_holidays;
    const errorValue = Object.values(validateUser).filter(
      (x) => x === "" || x === undefined || x < 1
    );

    let isValue = true;
    if (errorValue.length > 0) {
      toast.error("Giá trị không được để trống!");
      isValue = false;
    }
    if (!emailValidation(user.email) && user.email !== "admin") {
      toast.error("Email không đúng!");
      isValue = false;
    }
    if (!phoneValidation(user.phone)) {
      toast.error("Số điện thoại không đúng!");
      isValue = false;
    }
    // eslint-disable-next-line
    if (user.paid_holidays == "") {
      toast.error("Tổng số ngày phép trong năm không được trống!");
      isValue = false;
    }
    // eslint-disable-next-line
    if (user.total_holidays == "") {
      toast.error("Tổng số ngày phép đang có không được trống!");
      isValue = false;
    }

    setIsLoading(false);

    setIsError(!isValue);
    return isValue;
  };
  const onCreateOrUpdateUser = async () => {
    onSetIsConfirm();

    if (!validateParam()) {
      return;
    }
    setIsLoading(true);

    const response = await API.createOrUpdateUser(
      "/api/users/user-save.php",
      user
    );

    if (response.success) {
      toast.success(
        id ? "Cập nhật thành công!" : "Tạo mới nhân viên thành công!"
      );
      if (!id) {
        history.push("/nhan-vien");
      } else {
        getUserDetail();
      }
    } else {
      toast.error(response.message);
    }

    setIsLoading(false);
  };

  const getDepartment = async () => {
    const res = await fetch(`/api/departments.php`).then((response) =>
      response.json()
    );
    if (res.success) {
      const department_values = (res.data || []).map((x) => {
        return { value: x.id, label: x.department_name };
      });
      setDepartments(department_values);
    }
  };

  const getPositions = async () => {
    const res = await fetch(`/api/positions.php`).then((response) =>
      response.json()
    );
    if (res.success) {
      setPositions(res.data);
    }
  };

  const onSetIsConfirm = () => {
    setIsConfirm(!isConfirm);
  };

  const onSetIsConfirmDel = (file_name) => {
    setFileNameDel(file_name);
    setIsdelete(!isdelete);
  };

  const onConfirmDelete = async () => {
    onSetIsConfirmDel();
    await API.getAPIData(
      `/upload/delete-file.php?file_name=${fileNameDel}`
    ).then((res) => {
      if (res.success) {
        toast.success(res.message);
        refetch();
      } else {
        toast.error(res.message);
      }
    });
  };

  const department_list = (departmentIds, department_values) => {
    const listDepartmentId = (departmentIds || "").split(",");

    return department_values.reduce((acc, cur) => {
      if (listDepartmentId.includes(cur.value)) {
        return [...acc, cur];
      }
      return acc;
    }, []);
  };

  return (
    <section>
      {isLoading && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header
          title={!id ? "Thêm mới nhân viên" : "Cập nhật thông tin nhân viên"}
        />
        <div className="container-fluid py-2">
          <div className="row">
            <div className="row my-2">
              <div className="col-md-12">
                <button
                  className="badge badge-sm btn-background-back"
                  onClick={() => onToListView()}
                >
                  <span className="mx-2">
                    <Icon.ArrowLeft size={15} /> Trở về
                  </span>
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="card my-1">
                <div className="card-body pb-2">
                  <h5>Thông tin cá nhân</h5>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Họ và tên: </label>
                      <div
                        className={
                          isError && isEmpty(user.full_name)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.full_name}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.full_name = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>
                        Email:{" "}
                        <span style={{ fontSize: "12px", color: "red" }}>
                          {!id &&
                            "**Email sẽ không được thay đổi khi đã được tạo"}
                        </span>
                      </label>
                      <div
                        className={
                          isError && isEmpty(user.email)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.email}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.email = e.target.value;
                            setUser({ ...newUser });
                          }}
                          disabled={!!id}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12">
                      <label>Địa chỉ: </label>
                      <div
                        className={
                          isError && isEmpty(user.address)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.address}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.address = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Giới tính: </label>
                      <div
                        className={
                          isError && isEmpty(user.sex)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.sex}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.sex = e.target.value;
                            setUser({ ...newUser });
                          }}
                        >
                          {Constant.sex_data.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>CMND/CCCD: </label>
                      <div
                        className={
                          isError && isEmpty(user.id_card)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.id_card}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.id_card = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Ngày sinh: </label>
                      <div
                        className={
                          isError && isEmpty(user.birth_date)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={user.birth_date || ""}
                            onChange={(newValue) => {
                              const date = new Date(newValue.$d).toLocaleString(
                                "af-ZA"
                              );
                              const newUser = user;
                              newUser.birth_date = date;
                              setUser({ ...newUser });
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Số điện thoại: </label>
                      <div
                        className={
                          isError && isEmpty(user.phone)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.phone}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.phone = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Mã số thuế: </label>
                      <div
                        className={
                          isError && isEmpty(user.tax_code)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.tax_code}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.tax_code = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Số BHXH: </label>
                      <div
                        className={
                          isError && isEmpty(user.insurance_code)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.insurance_code}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.insurance_code = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Cấp bậc: </label>
                      <div
                        className={
                          isError && isEmpty(user.rank)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.rank}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.rank = e.target.value;
                            setUser({ ...newUser });
                          }}
                        >
                          {Constant.rank.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Vị trí: </label>
                      <div
                        className={
                          isError && user.position_id < 1
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.position_id}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.position_id = e.target.value;
                            setUser({ ...newUser });
                          }}
                        >
                          {positions.map((value, index) => {
                            return (
                              <option value={value.id} key={index}>
                                {value.position_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Team: </label>
                      <div
                        className={
                          isError && isEmpty(user.team)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.team}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.team = e.target.value;
                            setUser({ ...newUser });
                          }}
                        >
                          {Constant.teams.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Ngày vào làm: </label>
                      <div
                        className={
                          isError && isEmpty(user.working_date)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={user.working_date || ""}
                            onChange={(newValue) => {
                              if (newValue) {
                                const date = new Date(
                                  newValue.$d
                                ).toLocaleString("af-ZA");
                                const newUser = user;
                                newUser.working_date = date;
                                setUser({ ...newUser });
                              }
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-md-12 mb-md-0 mb-4">
                      <label>Phòng ban: </label>
                      <div
                        className={
                          isError && isEmpty(user.departments)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <Select
                          options={departments}
                          isMulti
                          closeMenuOnSelect={false}
                          className="width-100"
                          value={department_list(user.departments, departments)}
                          onChange={(e) => {
                            const newUser = user;
                            if (e.length > 0) {
                              const newValues = e.map((x) => x.value);
                              newUser.departments = newValues.join(",");
                            } else {
                              newUser.departments = "";
                            }
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Trạng thái nhân viên: </label>
                      <div
                        className={
                          isError && isEmpty(user.status)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.status}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.status = e.target.value;
                            setUser({ ...newUser });
                          }}
                          disabled={!id}
                        >
                          {Constant.status_user.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Trạng thái tài khoản: </label>
                      <div
                        className={
                          isError && isEmpty(user.isdeleted)
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <select
                          className="form-control"
                          value={user.isdeleted}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.isdeleted = e.target.value;
                            setUser({ ...newUser });
                          }}
                          disabled={!id}
                        >
                          {Constant.status_account.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Tổng số ngày phép trong năm: </label>
                      <div
                        className={
                          // eslint-disable-next-line
                          isError && user.total_holidays == ""
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.total_holidays}
                          maxLength={4}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.total_holidays = e.target.value;
                            setUser({ ...newUser });
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-4">
                      <label>Tổng số ngày phép đang có: </label>
                      <div
                        className={
                          // eslint-disable-next-line
                          isError && user.paid_holidays == ""
                            ? "input-group input-group-outline border-input-error"
                            : "input-group input-group-outline"
                        }
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={user.paid_holidays}
                          maxLength={4}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.paid_holidays = e.target.value;
                            setUser({ ...newUser });
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12">
                      <label>Giới thiệu bản thân: </label>
                      <div className="input-group input-group-outline">
                        <textarea
                          type="text"
                          className="form-control"
                          rows="4"
                          value={user.introduce}
                          onChange={(e) => {
                            const newUser = user;
                            newUser.introduce = e.target.value;
                            setUser({ ...newUser });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pb-2" hidden={!id}>
                  <div className="d-flex justify-content-between">
                    <h5>Hồ sơ cá nhân</h5>
                    <div className="d-flex">
                      <label style={{ width: "100px", alignSelf: "center" }}>
                        Loại file:
                      </label>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control min-width-150"
                          value={typeDoc}
                          onChange={(e) => {
                            setTypeDoc(e.target.value);
                          }}
                        >
                          <option value={"hd"}>Hợp đồng</option>
                          <option value={"pl"}>Phiếu lương</option>
                          <option value={"kh"}>Khác...</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label style={{ width: "150px", alignSelf: "center" }}>
                        Thời gian upload:
                      </label>
                      <div>
                        <div
                          className="input-group input-group-outline"
                          style={{ width: "100px" }}
                        >
                          <select
                            className="form-control mx-1"
                            value={docMonth}
                            onChange={(e) => {
                              setDocMonth(e.target.value);
                            }}
                          >
                            <option value="0">All</option>
                            {Constant.select_month.map((value, index) => {
                              return (
                                <option value={value.value} key={index}>
                                  {value.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div style={{ width: "120px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year"]}
                            value={uploadAtDoc}
                            onChange={(newValue) => {
                              setUploadAtDoc(newValue.$d);
                            }}
                            inputFormat="YYYY"
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <button
                      className="badge badge-sm btn-background-violet float-right"
                      onClick={() => {
                        window.open(
                          `/upload/upload-files.php?key=${Constant.keyString}&id=${id}`,
                          ""
                        );
                      }}
                    >
                      <span className="mx-2">
                        <Icon.Upload size={15} /> Upload
                      </span>
                    </button>
                  </div>
                  <Documents
                    documents={documents}
                    onSetIsConfirmDel={onSetIsConfirmDel}
                  />
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="badge badge-sm btn-background-violet float-right"
                        onClick={() => onSetIsConfirm()}
                      >
                        <span className="mx-2">
                          <Icon.Plus size={15} />{" "}
                          {!id
                            ? " Thêm mới nhân viên "
                            : " Cập nhật thông tin "}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
      <Confirm
        visible={isConfirm}
        header={!id ? "Thêm mới nhân viên" : "Cập nhật thông tin nhân viên"}
        title={
          !id ? "Xác nhận thêm mới nhân viên?" : "Xác nhận cập nhật nhân viên?"
        }
        onClose={onSetIsConfirm}
        onConfirm={onCreateOrUpdateUser}
      />
      <Confirm
        visible={isdelete}
        header={"Xoá tài liệu"}
        title={"Bạn muốn xoá tài liệu?"}
        onClose={onSetIsConfirm}
        onConfirm={onConfirmDelete}
      />
    </section>
  );
}

export default UserCreate;
