import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import API from "../api";
import { toast } from "react-toastify";

export default function ReadNotification(props) {
  const { visible, onClose, onConfirm, id } = props;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [idNotifi, setIdNotifi] = useState("");

  useEffect(() => {
    if (id) {
      getNotification();
    }
  }, [visible]);

  const getNotification = async () => {
    await API.getAPIData(`/api/get-notification-detail.php?id=${id}`).then(
      (res) => {
        if (res.success) {
          const data = res.data;
          setContent(data.content);
          setTitle(data.title);
          setIdNotifi(data.id);
        }
      }
    );
  };
  const onCreateNotification = async () => {
    await API.postParam("/api/notification-read.php", {
      id: idNotifi,
    }).then((res) => {
      if (res.success) {
        setContent("");
        onConfirm();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="row">
          <p>{content}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Đóng
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onCreateNotification()}
          >
            Xác nhận
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
