import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import API from "../api";
import { toast } from "react-toastify";
import { isEmpty } from "../helper";
import RichTextEditor from "./RichTextEditor";

export default function CreateNews(props) {
  const { visible, onClose, onConfirm, id } = props;
  const [newsType, setNewsType] = useState([]);
  const [selectTypeCode, setSelectTypeCode] = useState("");
  const [quill, setQuill] = useState("");
  const [isError, setIsError] = useState(false);
  const [loadding, setLoading] = useState(true);

  useEffect(() => {
    getNewsType();
    if (id) {
      getNewsDetail();
    } else {
      setQuill("");
      setSelectTypeCode("");
      setLoading(false);
    }
  }, [id]);

  const getNewsDetail = async () => {
    await API.getAPIData(`/api/get-news-detail.php?id=${id}`).then((res) => {
      if (res.success) {
        setSelectTypeCode(res.data.type_code);
        setQuill(res.data.content);
        setLoading(false);
      }
    });
  };

  const getNewsType = async () => {
    await API.getAPIData("/api/news-type.php").then((res) => {
      if (res.success) {
        setNewsType(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleChange = (html) => {
    setQuill(html);
  };

  const onCreateNews = async () => {
    setIsError(false);
    if (isEmpty(selectTypeCode)) {
      setIsError(true);
      toast.error("Vui lòng chọn chủ đề!");
      return false;
    }

    if (isEmpty(quill)) {
      setIsError(true);
      toast.error("Vui lòng nhập nội dung!");
      return false;
    }
    setLoading(true);

    await API.postParam("/api/news-save.php", {
      content: quill.replaceAll("'", "&#39;"),
      type_code: selectTypeCode,
      id: id,
    })
      .then((res) => {
        if (res.success) {
          toast.success(id ? "Cập nhật thành công!" : "Tạo thành công!");
          setQuill("");
          onConfirm();
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const onDeleteNews = async () => {
    await API.getAPIData(`/api/news-delete.php?id=${id}`).then((res) => {
      if (res.success) {
        toast.success("Xoá thành công!");
        onConfirm();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>{id ? "Cập nhật" : "Tạo mới"} </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-auto my-2" style={{ alignSelf: "center" }}>
            <label>Chủ đề: </label>
          </div>
          <div className="col-auto my-2">
            <div
              className={
                isError && isEmpty(selectTypeCode)
                  ? "input-group input-group-outline border-input-error"
                  : "input-group input-group-outline"
              }
            >
              <select
                className="form-control"
                value={selectTypeCode}
                onChange={(e) => setSelectTypeCode(e.target.value)}
              >
                <option value={""}>----- Chọn chủ đề -----</option>
                {newsType.map((value) => {
                  return (
                    <option key={value.id} value={value.type_code}>
                      {value.type_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className={isError && isEmpty(quill) ? "border-input-error" : ""}>
          <RichTextEditor
            placeholder="Nội dung..."
            value={quill}
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-small btn-light mx-2"
            onClick={() => {
              onClose();
            }}
            disabled={loadding}
          >
            Huỷ
          </button>
          {!!id && (
            <Button
              className="bg-gradient-danger"
              color="danger"
              onClick={() => {
                onDeleteNews();
              }}
              disabled={loadding}
            >
              Xoá
            </Button>
          )}
          <Button
            className="bg-gradient-primary mx-2"
            color="primary"
            onClick={() => onCreateNews()}
            disabled={loadding}
          >
            {id ? "Cập nhật" : "Đăng tin"}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
