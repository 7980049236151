import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import parse from "html-react-parser";
import { timePost } from "../helper";
import API from "../api";
import Confirm from "./confirm";
import { toast } from "react-toastify";

function Post({
  data,
  onEditPost,
  imgComment,
  permission_code,
  show_delete_comment = false,
}) {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState(0);

  useEffect(() => {
    setComments(data.comments || []);
  }, [data]);

  const onSave = async (news_id) => {
    await API.postParam("/api/post/save-comment.php", {
      news_id: news_id,
      content: comment,
    }).then((res) => {
      if (res.success) {
        setComment("");
        setComments(res.data);
      }
    });
  };

  const onConfirmDelete = async () => {
    await API.postParam("/api/post/delete_comment.php", { id: commentId }).then(
      (res) => {
        if (res.success) {
          const comment = comments.filter((x) => x.id !== commentId);
          setComments(comment);
        } else {
          toast.error(res.message);
        }
      }
    );
    setCommentId(0);
  };

  const onDeleteComment = (id) => {
    setCommentId(id);
  };

  const onClose = () => {
    setCommentId(0);
  };

  return (
    <div className="card mt-4">
      <div className="card-header pb-0 p-3">
        <div className="row">
          <div className="col-auto">
            <div className="avatar avatar-lg position-relative">
              <img
                src={"/api/images/" + data.url_avata}
                alt="profile_image"
                className="w-100 border-radius-lg shadow-sm"
              />
            </div>
          </div>
          <div className="col-auto my-auto p-0">
            <div className="h-100">
              <h6 className="mb-1">{data.full_name}</h6>
              <p className="mb-0 font-weight-normal text-xs">
                {timePost(data.created_at || "")}
              </p>
            </div>
          </div>
          {permission_code.includes("post_home_page") && (
            <div className="col p-0 text-end mx-2">
              <span
                className="cursor-pointer"
                onClick={() => onEditPost(data.id)}
              >
                <Icon.Edit3 size={18} />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="card-body p-3">
        <div className="row bd-news-post">
          {parse(data.content || "")}
          <div className="card-footer">
            {comments.map((value, index) => {
              return (
                <div className="row mb-4" key={index}>
                  <div className="col-auto">
                    <div className="avatar avatar-lg rounded-circle">
                      <img
                        src={"/api/images/" + value.url_avata}
                        alt="profile_image"
                        className="w-100 border-radius-lg shadow-sm"
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 my-auto p-0">
                    <div className="h-100">
                      <div>
                        <h6 className="mb-1">{value.full_name}</h6>
                        {show_delete_comment && (
                          <button
                            className="delete-comment-post"
                            onClick={() => onDeleteComment(value.id)}
                          >
                            x
                          </button>
                        )}
                      </div>
                      <p className="mb-0 font-weight-normal text-sm">
                        {parse(value.content || "")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-auto">
                <div className="avatar avatar-lg rounded-circle">
                  <img
                    src={"/api/images/" + imgComment}
                    alt="profile_image"
                    className="w-100 border-radius-lg shadow-sm"
                  />
                </div>
              </div>
              <div className="col-md-9 my-auto p-0">
                <div className="h-100">
                  <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                    <div className="input-group input-group-outline">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Viết bình luận..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        maxLength={1000}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <button
                  className="btn btn-outline-info btn-sm mt-2"
                  onClick={() => onSave(data.id)}
                >
                  Gửi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirm
        visible={!!commentId}
        onClose={onClose}
        onConfirm={onConfirmDelete}
        header="Xóa bình luận"
        title="Xác nhận xóa bình luận"
      />
    </div>
  );
}

export default Post;
