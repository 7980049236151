// eslint-disable-next-line no-use-before-define
export function caculatePage(totalPages, size = 15) {
  const calPage = totalPages / size;
  const intPage = Math.floor(calPage);

  if (calPage !== intPage) {
    return intPage + 1;
  } else {
    return intPage;
  }
}

export function caculateOffSet(page, size = 15) {
  if (page < 1) return page;
  return size * page - size;
}

export function currencyFormat(num) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export function phoneValidation(numPhone) {
  const regex = /^(84|0[3|5|7|8|9])+([0-9]{8})\b/i;
  return !(!numPhone || regex.test(numPhone) === false);
}

export function emailValidation(email) {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
  return !(!email || regex.test(email) === false);
}

export function importAll(r) {
  let images = {}; // eslint-disable-next-line
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export function convertYMD(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function convertDMY(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}

export function convertMDY(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join("-");
}

export function convertMDYHHMM(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2),
    hours = ("0" + date.getHours()).slice(-2),
    minutes = ("0" + date.getMinutes()).slice(-2);

  return [date.getFullYear(), mnth, day].join("-") + ` ${hours}:${minutes}`;
}

export function removeAccents(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
}

export function isEmpty(val) {
  return val === undefined ||
    val === null ||
    val.length < 1 ||
    val === "" ||
    val === "Invalid Date"
    ? true
    : false;
}

export function postUrlParam(url, param) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams(param),
    };
    return fetch(url, requestOptions).then((response) => response.json());
  } catch (error) {
    return { success: false, mesage: "Internal server error!" };
  }
}

export function getUrl(url) {
  try {
    return fetch(url).then((response) => response.json());
  } catch (error) {
    return { success: false, mesage: "Internal server error!" };
  }
}

export function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}

export function timePost(datetime) {
  const milliseconds = new Date() - new Date(datetime);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const seconds = Math.floor((milliseconds / 1000) % 60);

  var returnValue = datetime;
  if (milliseconds >= 86400000) {
    return returnValue;
  } else if (hours < 24 && hours > 0) {
    returnValue = hours + " giờ trước";
  } else if (minutes !== 0) {
    returnValue = minutes + " phút trước";
  }
  if (seconds < 60) {
    returnValue = "Mới";
  }
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function targetMonth(month) {
  if (month <= 3) return 1;
  else if (month > 3 && month <= 6) return 2;
  else if (month > 6 && month <= 9) return 3;
  else if (month > 9) return 4;
}

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  const stringReplace = string.replaceAll("_", " ");
  return stringReplace.charAt(0).toUpperCase() + stringReplace.slice(1);
}

export function daysdifference(firstDate, secondDate) {
  var startDay = new Date(firstDate);
  var endDay = new Date(secondDate);

  var millisBetween = startDay.getTime() - endDay.getTime();
  var days = millisBetween / (1000 * 3600 * 24);

  return Math.round(Math.abs(days));
}

export function add30Minutes(timeString) {
  const [hour, minute] = timeString.split(":");
  let date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  date.setMinutes(date.getMinutes() + 30);
  let hourWithZero = date
    .getHours()
    .toString()
    .padStart(2, "0");
  let minuteWithZero = date
    .getMinutes()
    .toString()
    .padStart(2, "0");
  return `${hourWithZero}:${minuteWithZero}`;
}

export function firstTimeisGreaterThanSecondTime(stringTFrom, stringTimeTTo) {
  const [hourF, minuteF] = stringTFrom.split(":");
  const [hourT, minuteT] = stringTimeTTo.split(":");

  const timeF = new Date(0, 0, 0, hourF, minuteF);
  const timeT = new Date(0, 0, 0, hourT, minuteT);

  if (timeF.getTime() > timeT.getTime()) {
    return true;
  } else {
    return false;
  }
}

export function setHoursAndMinuteForDay(date, timeString) {
  if (!date) {
    date = new Date();
  } else {
    date = new Date(date);
  }
  const [hour, minute] = timeString.split(":");

  date.setHours(hour);
  date.setMinutes(minute);
  return date;
}

export function addZeroNumber(num) {
  return num.toString().padStart(2, "0");
}

function isInteger(num) {
  return num % 1 === 0;
}

export function totalKpiFormat(num) {
  if (isInteger(num)) {
    var myString = Math.floor(num).toFixed(2); // Chuyển số thành chuỗi với 2 chữ số thập phân
    var result = parseFloat(myString); // Chuyển lại thành số float
    return result;
  } else {
    return num;
  }
}

// value type {$d: 12,  $m: 12, $y: 2023}
export function selectDatePicker(value, time) {
  time = time ? time : `${value.$H}:${value.$m}:${value.$ms}`;
  if (value) {
    const month = value.$M + 1;
    return `${value.$y}-${month}-${value.$D} ${time}`;
  } else return "";
}

export function setTimeDefaultDatePicker(time) {
  var date = new Date();
  var setTime = "";
  if (time) {
    setTime = time;
  } else {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    setTime = `${hours}:${minutes}:00`;
  }
  const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return [date.getFullYear(), mnth, day].join("-") + " " + setTime;
}
