import React, { useState, useEffect } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import NavBar from "../Components/navbar";
import * as Icon from "react-feather";
import { caculateOffSet, caculatePage, convertDMY } from "../helper";
import Loading from "../Components/loading";
import Confirm from "../Components/confirm";
import { toast } from "react-toastify";
import API from "../api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { select_month } from "../constant";
import "dayjs/locale/fr";
import { UncontrolledTooltip } from "reactstrap";
import Paginator from "../Components/paginato";

function LeaveRequest() {
  document.title = "Duyệt Nghỉ phép - Ngày off";
  const dateNow = new Date();
  const [leaveOffs, setLeaveOffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState("");
  const [selectYear, setSelectYear] = useState(dateNow);
  const [selectMonth, setSelectMonth] = useState(dateNow.getMonth() + 1);
  const [userName, setUserName] = useState("");
  const [isApprove, setIsApprove] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [departments, setDepartments] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [rank, setRank] = useState("");
  const [page, setPage] = useState({
    page: 0,
    size: 10,
    totalPages: 10,
  });
  const size = 10;

  useEffect(() => {
    getLeaveOffs(1);
  }, []);

  const getLeaveOffs = async (newPage) => {
    setIsLoading(true);

    let url = `/api/leave_off/leave_off.php?offset=${caculateOffSet(
      newPage || page.page,
      size
    )}&size=${size}&year=${selectYear.getFullYear()}&month=${selectMonth}&approve=${isApprove}&key_search=${inputSearch}`;

    const res = await API.getAPIData(url);

    if (res.success) {
      setLeaveOffs(res.data);
      setPage({
        page: newPage || page.page,
        size: size,
        totalPages: caculatePage(res.total, size),
      });
      setDepartments(res.departments || []);
      setCurrentUserId(res.user_id);
      setRank(res.rank);
    } else {
      setLeaveOffs([]);
      setPage({
        page: 0,
        size: size,
        totalPages: 0,
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (newPage) => {
    getLeaveOffs(newPage);
  };

  const onClose = (id, full_name) => {
    setUserName(full_name);
    setID(id);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    onClose();
    await API.getAPIData(`/api/leave_off/leave_off_user_approve.php?id=${id}`)
      .then((res) => {
        if (res.success) {
          getLeaveOffs();
          toast.success("Đã duyệt thành công!");
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Duyệt Nghỉ phép - Ngày off" />
        <div className="container-fluid py-2">
          {isLoading && <Loading />}
          <div className="row">
            <div className="row my-2">
              <div className="col-md-3">
                <div className="input-group input-group-outline">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tìm theo tên hoặc email"
                    value={inputSearch}
                    onChange={(e) => setInputSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex col-md-3">
                <div style={{ alignSelf: "center", width: "70px" }}>Month:</div>
                <div
                  className="input-group input-group-outline"
                  style={{ width: "200px" }}
                >
                  <select
                    className="form-control"
                    value={selectMonth}
                    onChange={(e) => {
                      setSelectMonth(e.target.value);
                    }}
                  >
                    <option value={0}>All</option>
                    {select_month.map((item) => {
                      return (
                        <option value={item.value} key={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex col-md-3">
                <div style={{ alignSelf: "center", width: "70px" }}>Year: </div>
                <div
                  className="input-group input-group-outline"
                  style={{ width: "200px", display: "inline-block" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      value={selectYear}
                      onChange={(newValue) => {
                        setSelectYear(newValue.$d);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="d-flex col-md-3">
                <input
                  type={"checkbox"}
                  checked={isApprove}
                  onChange={() => {
                    setIsApprove(!isApprove);
                  }}
                />
                <span style={{ alignSelf: "center", marginLeft: "5px" }}>
                  Đơn chờ duyệt
                </span>
              </div>
              <div className="d-flex col-md-12 my-2">
                <div style={{ width: "100%" }}>
                  <button
                    className="badge badge-sm btn-background-violet float-right"
                    onClick={() => {
                      getLeaveOffs();
                    }}
                  >
                    <span className="mx-3">
                      <Icon.Search size={18} /> Tìm
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-body px-0 pt-0">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bolder">
                            Nhân viên
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder">
                            Loại đơn
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder">
                            Trạng thái
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                            Nội dung
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Từ ngày
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Đến ngày
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Tổng ngày nghỉ
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveOffs.map((value, index) => {
                          const departmentArr = (value.departments || "").split(
                            ","
                          );

                          const hasDeparment = departmentArr.map((element) => {
                            return !!departments.find(
                              (x) => x.id === `${element}`
                            );
                          });

                          // check user in commont deparment
                          const isCommonDepartment =
                            hasDeparment.find((x) => x === true) || false;

                          let canApprove = false;
                          switch (rank) {
                            case "officer":
                              break;
                            case "head":
                              canApprove =
                                isCommonDepartment &&
                                currentUserId !== value.user_id;
                              break;
                            case "bod":
                              canApprove = true;
                              break;
                            default:
                              break;
                          }

                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6
                                      className="mb-0 text-sm"
                                      id={`name_tooltip_${index}`}
                                    >
                                      {value.full_name}
                                    </h6>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`name_tooltip_${index}`}
                                    >
                                      Tổng: {value.total_holidays}/
                                      {value.paid_holidays}
                                    </UncontrolledTooltip>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="text-xs font-weight-bold mb-0">
                                  {value.type_name}
                                </div>
                              </td>
                              <td>
                                <div className="text-xs font-weight-bold mb-0">
                                  {value.status === "1"
                                    ? "Đã duyệt"
                                    : "Chờ duyệt"}
                                </div>
                              </td>
                              <td>
                                <p
                                  className="text-xs font-weight-bold mb-0"
                                  style={{ whiteSpace: "normal" }}
                                >
                                  {value.content}
                                </p>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <span className="text-secondary text-xs font-weight-bold">
                                  {convertDMY(value.off_from)}
                                </span>
                              </td>
                              <td className="align-middle text-center">
                                <span className="text-secondary text-xs font-weight-bold">
                                  {convertDMY(value.off_to)}
                                </span>
                              </td>
                              <td className="align-middle text-center">
                                <span id={`date_tooltip_${index}`}>
                                  {value.total_day_off}
                                </span>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`date_tooltip_${index}`}
                                >
                                  Tổng: {value.total_holidays}/
                                  {value.paid_holidays},
                                </UncontrolledTooltip>
                              </td>
                              <td className="align-middle text-center">
                                {canApprove && value.status === "0" && (
                                  <span
                                    className="text-secondary font-weight-bold text-xs cursor-pointer p-1"
                                    onClick={() =>
                                      onClose(value.id, value.full_name)
                                    }
                                  >
                                    <Icon.CheckSquare
                                      size={20}
                                      color="#0d33f9"
                                    />
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {!!page.page && (
                    <div className="page-right">
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
      <Confirm
        visible={!!id}
        header={"Duyệt ngày phép/off"}
        title={`Bạn muốn xác nhận duyệt ngày phép của ${userName}?`}
        onClose={onClose}
        onConfirm={onSubmit}
      />
    </section>
  );
}

export default LeaveRequest;
