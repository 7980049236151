import React, { useState, useEffect } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import NavBar from "../Components/navbar";
import Paginator from "../Components/paginato";
import { useHistory } from "react-router-dom";
import * as Icon from "react-feather";
import {
  caculatePage,
  caculateOffSet,
  capitalizeFirstLetter,
  isEmpty,
} from "../helper";
import userImg from "../../img/user-pase.jpeg";
import Loading from "../Components/loading";
import Confirm from "../Components/confirm";
import { toast } from "react-toastify";
import API from "../api";
import { sex_data, status_account, status_user, teams } from "../constant";
import useQueryParams from "../Hooks/useQueryParams";

function Users() {
  document.title = "Nhân viên";
  let history = useHistory();
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [idResetKey, setIdResetKey] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState({
    page: 1,
    size: 10,
    totalPages: 10,
  });

  const { params, updateFilter, updatePaginate } = useQueryParams({});
  const [searchTerm, setsearchTerm] = useState(params.search_term);

  useEffect(() => {
    getUsers();
    getDepartment();
    getPositions();
  }, []);

  const getUsers = async (newPage) => {
    const page = newPage || params.page;
    let url = `/api/users/users.php?offset=${caculateOffSet(
      page,
      params.size
    )}&size=${params.size}`;
    if (!isEmpty(params.search_term)) {
      url = url + `&search_term=${params.search_term}`;
    }
    if (!isEmpty(params.sex)) {
      url = url + `&sex=${params.sex}`;
    }
    if (!isEmpty(params.status)) {
      url = url + `&status=${params.status}`;
    }
    if (!isEmpty(params.active)) {
      url = url + `&active=${params.active}`;
    }
    if (!isEmpty(params.team)) {
      url = url + `&team=${params.team}`;
    }
    setIsLoading(true);

    const res = await fetch(url).then((response) => response.json());

    if (res.success) {
      setUsers(res.data);
      setPage({
        page: page,
        size: params.size,
        totalPages: caculatePage(res.total, params.size),
      });
      setTotal(res.total);
    } else {
      setUsers([]);
      setPage({
        page: 0,
        size: 0,
        totalPages: 0,
      });
      setTotal(0);
    }
    setIsLoading(false);
  };

  const getDepartment = async () => {
    let url = `/api/departments.php`;
    const res = await fetch(url).then((response) => response.json());
    if (res.success) {
      setDepartments(res.data);
    }
  };

  const getPositions = async () => {
    let url = `/api/positions.php`;
    const res = await fetch(url).then((response) => response.json());
    if (res.success) {
      setPositions(res.data);
    }
  };

  const onPageChange = (newPage) => {
    setPage({ ...page, page: newPage });
    updatePaginate({ page: newPage, size: 10 });
    getUsers(newPage);
  };

  const onCreateBtnClick = () => {
    history.push("/user/create");
  };

  const onConfirmResetPass = (id) => {
    setIdResetKey(id);
  };

  const onResetPassNull = () => {
    setIdResetKey("");
  };

  const onResetPassword = async () => {
    setIsLoading(true);
    const res = await API.resetPassword("/api/reset-password.php", {
      id: idResetKey,
    });
    onResetPassNull();
    if (res.success) {
      toast.success("Reset password thành công!");
      getUsers();
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const optionChange = (option, name) => {
    const selectedFilter = {};
    selectedFilter[name] = option ? option.target.value : "";
    updateFilter({
      ...params,
      ...selectedFilter,
    });
  };

  return (
    <section>
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Danh sách nhân viên" />
        <div className="container-fluid py-2">
          {isLoading && <Loading />}
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body">
                  <div className="row my-2">
                    <div className="col-md-4">
                      <div className="input-group input-group-outline">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tìm theo tên hoặc email"
                          value={searchTerm}
                          onChange={(option) =>
                            setsearchTerm(option.target.value)
                          }
                          onBlur={(e) => {
                            updateFilter({
                              ...params,
                              ...{ search_term: e.target.value },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex my-2">
                      <span className="lable-with-200">Team:</span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={params.team}
                          onChange={(option) => optionChange(option, "team")}
                        >
                          <option value={""}>Tất cả</option>
                          {teams.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex my-2">
                      <span className="lable-with-200">Giới tính:</span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={params.sex}
                          onChange={(option) => optionChange(option, "sex")}
                        >
                          <option value={""}>Tất cả</option>
                          {sex_data.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex my-2">
                      <span className="lable-with-200">Trạng thái NV:</span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={params.status}
                          onChange={(option) => optionChange(option, "status")}
                        >
                          <option value={""}>Tất cả</option>
                          {status_user.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex my-2">
                      <span className="lable-with-200">Trạng thái TK:</span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={params.active}
                          onChange={(option) => optionChange(option, "active")}
                        >
                          <option value={""}>Tất cả</option>
                          {status_account.map((value, index) => {
                            return (
                              <option value={value.value} key={index}>
                                {value.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4  my-2">
                      <button
                        className="badge badge-sm btn-background-violet float-right"
                        onClick={() => getUsers()}
                      >
                        <span className="mx-3">
                          <Icon.Search size={18} /> Tìm
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-12">
                <span>Tổng: {total} </span>
                <button
                  className="badge badge-sm btn-background-violet float-right"
                  onClick={() => onCreateBtnClick()}
                >
                  <span className="px-2">
                    <Icon.Plus size={15} /> Thêm nhân viên
                  </span>
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body px-0 pt-0">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bolder">
                            Họ và tên
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                            Giới tính
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                            Team
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                            Phòng ban
                          </th>
                          {/* <th className="text-uppercase text-xxs font-weight-bolder ps-2">
                            SDT
                          </th> */}
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Trạng thái NV
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Trạng thái TK
                          </th>
                          {/* <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Ngày vào làm
                          </th> */}
                          <th className="text-center text-uppercase text-xxs font-weight-bolder"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((value, index) => {
                          const department = departments.find(
                            (x) => x.id === value.department_id
                          );
                          const position = positions.find(
                            (x) => x.id === value.position_id
                          );
                          const sex = sex_data.find(
                            (x) => x.value === value.sex
                          );
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex px-2 py-1">
                                  <div>
                                    <img
                                      src={
                                        value.url_avata
                                          ? "/api/images/" + value.url_avata
                                          : userImg
                                      }
                                      className="avatar avatar-sm me-3 border-radius-lg"
                                      alt="user1"
                                    />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 text-sm">
                                      {value.full_name}
                                    </h6>
                                    <p className="text-xs text-secondary mb-0">
                                      {value.email}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {sex ? sex.label : ""}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {capitalizeFirstLetter(value.team)}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {department ? department.department_name : ""}
                                </p>
                                <p className="text-xs text-secondary mb-0">
                                  {position ? position.position_name : ""}
                                </p>
                              </td>
                              {/* <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {value.phone}
                                </p>
                              </td> */}
                              <td className="align-middle text-center text-sm">
                                <span className="badge badge-sm bg-gradient-success">
                                  {status_user.find(
                                    (x) => x.value === value.status
                                  ).label || ""}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <span className="badge badge-sm bg-gradient-success">
                                  {status_account.find(
                                    // eslint-disable-next-line
                                    (x) => x.value == value.isdeleted
                                  ).label || ""}
                                </span>
                              </td>
                              {/* <td className="align-middle text-center">
                                <span className="text-secondary text-xs font-weight-bold">
                                  {convertDMY(value.working_date)}
                                </span>
                              </td> */}
                              <td className="align-middle text-center">
                                <span
                                  className="text-secondary font-weight-bold text-xs cursor-pointer p-1"
                                  onClick={() => onConfirmResetPass(value.id)}
                                >
                                  <Icon.Key
                                    size={20}
                                    color="#3498db"
                                    fill="#4caf50"
                                  />
                                </span>
                                <span
                                  className="text-secondary font-weight-bold text-xs cursor-pointer p-1"
                                  onClick={() =>
                                    history.push(
                                      `/user/${value.id}${window.location.search}`
                                    )
                                  }
                                >
                                  <Icon.Edit
                                    size={20}
                                    color="#fff"
                                    fill="#8075ef"
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {!!page.page && (
                    <div className="page-right">
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
      <Confirm
        visible={!!idResetKey}
        header={"Reset password"}
        title={"Bạn muốn reset password?"}
        onClose={onResetPassNull}
        onConfirm={onResetPassword}
      />
    </section>
  );
}

export default Users;
