import React, { useState, useEffect } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import NavBar from "../Components/navbar";
import Paginator from "../Components/paginato";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  caculatePage,
  caculateOffSet,
  targetMonth,
  totalKpiFormat,
} from "../helper";
import TextField from "@mui/material/TextField";
import API from "../api";
import * as Icon from "react-feather";
import FileExcel from "../file/Onefamilyuser.xlsx";
import UploadButton from "../Components/UploadButton";
import ExcelJS from "exceljs/dist/exceljs";
import Loading from "../Components/loading";
import { toast } from "react-toastify";
import Confirm from "../Components/confirm";
import "dayjs/locale/fr";
import ListKpiUser from "../Components/list-kpi-user";

function KpiPage() {
  const dateNow = new Date();
  const monthN = dateNow.getMonth() + 1;
  const [data, setData] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [selectFromYear, setSelectFromYear] = useState(dateNow);
  const [selectFromTarget, setSelectFromTarget] = useState(targetMonth(monthN));
  const [selectToYear, setSelectToYear] = useState(dateNow);
  const [selectToTarget, setSelectToTarget] = useState(targetMonth(monthN));
  const [isLoading, setIsLoading] = useState(false);
  const [stringError, setStringError] = useState([]);
  const [idDeleted, setIdDeleted] = useState("");
  const [idDeletedUser, setIdDeletedUser] = useState("");
  const [isConfirmDel, setIsConfirmDel] = useState(false);
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [code, setCode] = useState([]);
  const [userDetailId, setUserDetailId] = useState("");
  const [userNameDetail, setUserNameDetail] = useState("");
  const [page, setPage] = useState({
    page: 0,
    size: 15,
    totalPages: 10,
  });

  const size = 10;
  useEffect(() => {
    getPermission();
    getListKPI(
      1,
      keySearch,
      selectFromYear.getFullYear(),
      selectFromTarget,
      selectToYear.getFullYear(),
      selectToTarget
    );
  }, []);

  const getListKPI = async (
    page,
    ipSearch,
    fromYear,
    fromTarget,
    toYear,
    toTarget
  ) => {
    let url = `/api/kpi/get-target.php?offset=${caculateOffSet(
      page,
      size
    )}&search_term=${ipSearch}&size=${size}&from_year=${fromYear}&from_target=${fromTarget}&to_year=${toYear}&to_target=${toTarget}`;
    setIsLoading(true);
    const res = await API.getAPIData(url);

    if (res.success) {
      setData(res.data);
      setPage({
        page: page,
        size: size,
        totalPages: caculatePage(res.total, size),
      });
    } else {
      setData([]);
      setPage({
        page: 0,
        size: 0,
        totalPages: 0,
      });
    }
    setIsLoading(false);
  };

  const onSearch = () => {
    getListKPI(
      1,
      keySearch,
      selectFromYear.getFullYear(),
      selectFromTarget,
      selectToYear.getFullYear(),
      selectToTarget
    );
  };

  const onReset = () => {
    setSelectFromTarget(targetMonth(monthN));
    setSelectToTarget(targetMonth(monthN));
    setKeySearch("");
    setSelectFromYear(dateNow);
    getListKPI(
      1,
      "",
      dateNow.getFullYear(),
      targetMonth(monthN),
      dateNow.getFullYear(),
      targetMonth(monthN)
    );
  };

  const getPermission = async () => {
    await API.getAPIData(`/api/get-user-permission.php`).then((res) => {
      if (res.success) {
        const ids = res.data.map((a) => a.code);
        if (ids.length) {
          setIsCreateEdit(ids.includes("create_delete_kpi"));
          setCode([...ids]);
        } else {
          setCode([]);
        }
      }
    });
  };

  const importTarget = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    let rows = [];
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const buffer = reader.result;
      let isValid = false;
      await wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet, id) => {
          sheet.eachRow((row, rowIndex) => {
            const value = row.values;
            if (rowIndex === 1) {
              if (
                value.toString() ===
                ",EMAIL,QUÝ,NĂM,WHAT_HOW,WHAT_HOW,WHAT_HOW,WHAT_HOW,WHAT_HOW,WHAT_HOW,WHAT_HOW,WHAT_HOW"
              ) {
                isValid = true;
              } else {
                isValid = false;
                toast.error(
                  "File không hợp lệ vui lòng tải lại file template."
                );
              }
            } else if (isValid && rowIndex > 2) {
              let email = "";
              if (typeof value[1] === "string" || value[1] instanceof String) {
                email = value[1] ? value[1].toString() : "";
              } else {
                email = value[1] ? value[1].text : "";
              }
              rows.push({
                email: email,
                target: value[2],
                year: value[3],
                what_trust: value[4],
                what_creation: value[6],
                what_passion: value[8],
                what_total_score:
                  typeof value[10] === "object" ? value[10].result : value[7],
                how_trust: value[5],
                how_creation: value[7],
                how_passion: value[9],
                how_total_score:
                  typeof value[11] === "object" ? value[11].result : value[7],
              });
            }
          });
        });
        if (isValid) {
          API.postParamArray("/api/save-target.php", rows).then((res) => {
            if (res.success) {
              toast.success("Upload Thành công!");
              setStringError([]);
              getListKPI(
                1,
                keySearch,
                selectFromYear.getFullYear(),
                selectFromTarget,
                selectToYear.getFullYear(),
                selectToTarget
              );
            } else {
              toast.error(res.message);

              if (res.error_data) {
                setStringError(res.error_data);
              }
            }
          });
        }
      });
      setIsLoading(false);
    };
  };

  const onPageChange = (newPage) => {
    getListKPI(
      newPage,
      keySearch,
      selectFromYear.getFullYear(),
      selectFromTarget,
      selectToYear.getFullYear(),
      selectToTarget
    );
  };

  const onDeletedKPI = (delId) => {
    setIdDeleted(delId);
    setIsConfirmDel(!isConfirmDel);
  };

  const onDeleted = async () => {
    await API.getAPIData(`/api/delete-user-target.php?id=${idDeleted}`).then(
      (res) => {
        if (res.success) {
          getListKPI(
            page.page,
            keySearch,
            selectFromYear.getFullYear(),
            selectFromTarget,
            selectToYear.getFullYear(),
            selectToTarget
          );
          onDeletedKPI();
          toast.success("Deleted successfully");
        } else {
          toast.error(res.messages);
        }
      }
    );
  };

  return (
    <section>
      {isLoading && <Loading />}
      <NavBar code={code} />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="KPI" />
        <div className="container-fluid py-2">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body">
                  <div className="row my-2">
                    <div className="col-md-3 mb-md-0 mb-4">
                      <div className="input-group input-group-outline">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tìm tên hoặc email"
                          value={keySearch}
                          onChange={(e) => setKeySearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 mb-md-0 mb-4 d-flex">
                      <span style={{ alignSelf: "center", width: "100px" }}>
                        Từ Quý:{" "}
                      </span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={selectFromTarget}
                          onChange={(e) => {
                            setSelectFromTarget(e.target.value);
                          }}
                        >
                          <option value={0}>Cả năm</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 mb-md-0 mb-4 d-flex">
                      <span style={{ alignSelf: "center", width: "50px" }}>
                        Năm: {""}
                      </span>

                      <div className="input-group input-group-outline ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year"]}
                            value={selectFromYear}
                            onChange={(newValue) => {
                              setSelectFromYear(newValue.$d);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col-md-2 mb-md-0 mb-4 d-flex">
                      <span style={{ alignSelf: "center", width: "110px" }}>
                        Đến quý:{" "}
                      </span>
                      <div className="input-group input-group-outline">
                        <select
                          className="form-control"
                          value={selectToTarget}
                          onChange={(e) => {
                            setSelectToTarget(e.target.value);
                          }}
                        >
                          <option value={0}>Cả năm</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 mb-md-0 mb-4 d-flex">
                      <span style={{ alignSelf: "center", width: "50px" }}>
                        Năm: {""}
                      </span>

                      <div className="input-group input-group-outline ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year"]}
                            value={selectToYear}
                            onChange={(newValue) => {
                              setSelectToYear(newValue.$d);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-3 mb-md-0 mb-4"></div>
                    <div className="col-md-2 mb-md-0 mb-4 d-flex"></div>
                    <div className="col-md-3 mb-md-0 mb-4 d-flex"></div>
                    <div className="col-md-4 mb-md-0 mb-4">
                      <button
                        className="badge badge-sm btn-background-gr float-right"
                        onClick={() => onSearch()}
                      >
                        <span className="mx-3">
                          <Icon.Search size={18} /> Tìm
                        </span>
                      </button>
                      <button
                        className="badge badge-sm btn-background-back float-right mx-2"
                        onClick={() => onReset()}
                      >
                        <span className="mx-3">
                          <Icon.Code size={18} /> Reset
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-6">
                <ul>
                  {stringError.map((value, index) => {
                    return (
                      <li key={index} className="text-error">
                        - {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-6">
                <UploadButton
                  className="badge badge-sm btn-background-violet float-right mx-2"
                  id="upload-sku"
                  name="Import"
                  onChange={importTarget}
                  accept=".xlsx"
                  isDisabled={!isCreateEdit}
                />
                <button
                  className="badge badge-sm btn-background-green float-right"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.setAttribute("target", "_blank");
                    link.setAttribute("href", FileExcel);
                    link.setAttribute("download", "Onefamilyuser.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                >
                  <Icon.Download size={18} /> Download file template
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="card my-1">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            rowSpan={2}
                            className="text-uppercase text-xxs font-weight-bolder"
                          >
                            Nhân viên
                          </th>
                          <th
                            rowSpan={2}
                            className="text-center text-uppercase text-xxs font-weight-bolder"
                          >
                            Quý
                          </th>
                          <th
                            rowSpan={2}
                            className="text-center text-uppercase text-xxs font-weight-bolder"
                          >
                            Năm
                          </th>
                          <th
                            colSpan={4}
                            className="text-center text-uppercase text-xxs font-weight-bolder"
                          >
                            WHAT_HOW
                          </th>

                          <th
                            rowSpan={2}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                        <tr>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Tin cậy
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Sáng tạo
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Đam mê
                          </th>
                          <th className="text-center text-uppercase text-xxs font-weight-bolder">
                            Điểm tổng kết
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  setUserDetailId(value.id);
                                  setUserNameDetail(value.full_name);
                                }}
                                className="cursor-pointer"
                              >
                                <div className="d-flex px-2 py-1">
                                  <div>
                                    <img
                                      src={"/api/images/" + value.url_avata}
                                      className="avatar avatar-sm me-3 border-radius-lg"
                                      alt="user1"
                                    />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 text-sm">
                                      {value.full_name}
                                    </h6>
                                    <p className="text-xs text-secondary mb-0">
                                      {value.email}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle text-center text-sm">
                                {value.target}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {value.year}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {totalKpiFormat(value.what_trust)}_
                                {totalKpiFormat(value.how_trust)}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {totalKpiFormat(value.what_creation)}_
                                {totalKpiFormat(value.how_creation)}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {totalKpiFormat(value.what_passion)}_
                                {totalKpiFormat(value.how_passion)}
                              </td>
                              <td className="align-middle text-center text-sm">
                                <span className="badge badge-sm bg-gradient-success">
                                  {totalKpiFormat(value.what_total_score)}
                                </span>{" "}
                                <span className="badge badge-sm bg-gradient-success">
                                  {totalKpiFormat(value.how_total_score)}
                                </span>
                              </td>
                              <td className="align-middle text-center">
                                <button
                                  className="text-secondary font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="Edit user"
                                  onClick={() => {
                                    setIdDeletedUser(value.email);
                                    onDeletedKPI(value.targets_id);
                                  }}
                                  disabled={!isCreateEdit}
                                >
                                  {isCreateEdit ? (
                                    <Icon.X
                                      size={20}
                                      color="#e91e63"
                                      fill="#e91e63"
                                    />
                                  ) : (
                                    <Icon.X size={20} fill="#e91e63" />
                                  )}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {!!page.page && (
                    <div className="page-right">
                      <Paginator
                        {...page}
                        onPageChange={(newpage) => onPageChange(newpage)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
      <Confirm
        visible={isConfirmDel}
        header={"Deleted target"}
        title={"Are you sure you want to deleted " + idDeletedUser}
        onClose={onDeletedKPI}
        onConfirm={onDeleted}
      />
      <ListKpiUser
        visible={!!userDetailId}
        id={userDetailId}
        onClose={setUserDetailId}
        name={userNameDetail}
      />
    </section>
  );
}

export default KpiPage;
