import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import API from "../api";
import { toast } from "react-toastify";
import { daysdifference, isEmpty } from "../helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import "dayjs/locale/fr";
import { sms_error } from "../constant";

export default function CreateLeaveOff(props) {
  const { visible, onClose, onConfirm, id } = props;
  const dateNow = new Date().toLocaleString("af-ZA");
  const [selectedType, setSelectedTypes] = useState("");
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [types, setTypes] = useState([]);
  const [numDateOff, setNumDateOff] = useState(0);
  const [selectDateOff, setSelectDateOff] = useState(1);

  useEffect(() => {
    if (id) {
      getLeaveOffDetail();
    } else {
      setContent("");
      setDateFrom(dateNow);
      setDateTo(dateNow);
      setSelectedTypes("");
    }
    getLeaveOffType();
  }, [visible]);

  const getLeaveOffType = async () => {
    const res = await API.getAPIData("/api/leave_off/leave_off_type.php");
    if (res.success) {
      setTypes(res.data);
    }
  };

  const getLeaveOffDetail = async () => {
    await API.getAPIData(
      `/api/leave_off/leave_off_user_detail.php?id=${id}`
    ).then((res) => {
      if (res.success) {
        const data = res.data;
        setContent(data.content);
        setDateFrom(data.off_from);
        setDateTo(data.off_to);
        onChangeType(data.type);
        setSelectDateOff(daysdifference(data.off_from, data.off_to) + 1);
      }
    });
  };

  const validateParam = () => {
    let isValue = true;
    if (isEmpty(content)) {
      toast.error("Nội dung không được trống!");
      isValue = false;
    }

    setIsError(!isValue);
    return isValue;
  };

  const onCreateNotification = async () => {
    if (!validateParam()) {
      return;
    }

    await API.postParam("/api/leave_off/leave_off_user_save.php", {
      content: content,
      date_form: dateFrom,
      date_to: dateTo,
      id: id || 0,
      type: selectedType,
      total_day: selectDateOff * numDateOff,
    }).then((res) => {
      if (res.success) {
        toast.success(id ? "Cập nhật thành công!" : "Thêm mới thành công!");
        setContent("");
        onConfirm();
      } else {
        toast.error(res.message || sms_error);
      }
    });
  };

  const onChangeType = (type) => {
    const dayOff = types.find((x) => x.short_name === type);
    if (dayOff) {
      setNumDateOff(dayOff.day_off);
    }

    setSelectedTypes(type);
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>
        {id ? "Cập nhật thông tin ngày phép" : "Tạo đơn phép"}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-2 my-2">
            <label>Loại phép: </label>
          </div>
          <div className="col-md-10 my-2">
            <div
              className={
                isError && isEmpty(selectedType)
                  ? "input-group input-group-outline border-input-error"
                  : "input-group input-group-outline"
              }
            >
              <select
                className="form-control"
                value={selectedType}
                onChange={(e) => {
                  onChangeType(e.target.value);
                }}
              >
                <option value={0}>-- Chọn loại đơn --</option>
                {types.map((value, index) => {
                  return (
                    <option
                      value={value.short_name}
                      key={index}
                      id={value.total_day_off}
                    >
                      {value.type_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 my-2">
            <label>Ngày phép: </label>
          </div>
          <div className="col-md-10 my-2">
            <div className="row ">
              <div className={"col-sm-4"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateFrom}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = new Date(newValue.$d).toLocaleString(
                          "af-ZA"
                        );

                        if (
                          new Date(date) - new Date(dateTo) > 0 ||
                          dateTo === "0000-00-00 00:00:00"
                        ) {
                          setDateTo(date);
                          setSelectDateOff(daysdifference(date, date) + 1);
                        } else {
                          setSelectDateOff(daysdifference(date, dateTo) + 1);
                        }
                        setDateFrom(date);
                      }
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className={"col-sm-1"} style={{ alignSelf: "center" }}>
                to
              </div>
              <div className={"col-sm-4"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dateTo}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = new Date(newValue.$d).toLocaleString(
                          "af-ZA"
                        );

                        setDateTo(date);
                        setSelectDateOff(daysdifference(dateFrom, date) + 1);
                      }
                    }}
                    inputFormat="DD/MM/YYYY"
                    minDate={dateFrom}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div style={{ alignSelf: "center" }}>
              Số ngày nghỉ: {selectDateOff * numDateOff}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 my-2">
            <label>Lý do: </label>
          </div>
          <div className="col-md-10 my-2">
            <div
              className={
                isError && isEmpty(content)
                  ? "input-group input-group-outline height-auto border-input-error"
                  : "input-group input-group-outline height-auto"
              }
            >
              <textarea
                className="form-control"
                style={{ height: "70px" }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Huỷ
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onCreateNotification()}
          >
            {id ? "Cập nhật" : "Thêm mới"}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
